document.addEventListener("DOMContentLoaded", function () {
    const dropdowns = document.querySelectorAll(".dropdown");

    dropdowns.forEach((dropdown) => {
        dropdown.addEventListener("mouseover", function () {
            const menu = this.querySelector(".dropdown-menu");
            menu.classList.add("show");

            const submenu = this.querySelector(".dropdown-submenu");
            if (window.innerWidth > 991) {
                if (submenu) {
                    const rect = submenu.getBoundingClientRect();
                    const viewportWidth = window.innerWidth;

                    if (rect.right > viewportWidth) {
                        submenu.style.left = "auto";
                        submenu.style.right = "100%";
                    } else {
                        submenu.style.left = "100%";
                        submenu.style.right = "auto";
                    }
                }
            }
        });

        dropdown.addEventListener("mouseout", function () {
            const menu = this.querySelector(".dropdown-menu");
            const submenu = this.querySelector(".dropdown-submenu");
            menu.classList.remove("show");
            if (window.innerWidth > 991) {
                if (submenu) {
                    submenu.style.left = "";
                    submenu.style.right = "";
                }
            }
        });
    });
});
